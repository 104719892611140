import Cookies from 'js-cookie'

const TokenKey = 'MyAdmin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}

// 保存用户openid
const openidKey = 'openid'
export function getOpenId() {
  return Cookies.get(openidKey)
}
export function setOpenId(openid) {
  return Cookies.set(openidKey, openid)
}
export function removeOpenId(openid) {
  return Cookies.remove(openidKey, openid)
}

// 获取招聘token
const accToken = 'ZPQY-Token'
export function setaccToken(token) {
  return Cookies.set(accToken, token)
}
export function getaccToken() {
  return Cookies.get(accToken)
}
export function removeaccToken() {
  return Cookies.remove(accToken)
}
// 获取网格管理token
const wgToken = 'WGGL-Token'
export function setwgToken(token) {
  return Cookies.set(wgToken, token)
}
export function getwgToken() {
  return Cookies.get(wgToken)
}
// 获取59岁老人采集token
const oldmanToken = 'LRCJ-Token'
export function setoldmanToken(token) {
  return Cookies.set(oldmanToken, token)
}
export function getoldmanToken() {
  return Cookies.get(oldmanToken)
}
export function removeoldmanToken() {
  return Cookies.remove(oldmanToken)
}

// 获取养老美团订单id
const ylOrderId = 'Order-Id'
export function setOrderId(id) {
  return Cookies.set(ylOrderId, id)
}
export function getOrderId() {
  return Cookies.get(ylOrderId)
}
export function removeOrderId() {
  return Cookies.remove(ylOrderId)
}

// 获取服务站登记
const StaLevel = 'staLevel'
export function setStaLevel(id) {
  return Cookies.set(StaLevel, id)
}
export function getStaLevel() {
  return Cookies.get(StaLevel)
}
export function removeStaLevel() {
  return Cookies.remove(StaLevel)
}

// 获取登录社区编码
const OCode = 'oCode'
export function setOCode(id) {
  return Cookies.set(OCode, id)
}
export function getOCode() {
  return Cookies.get(OCode)
}
export function removeOCode() {
  return Cookies.remove(OCode)
}

// 获取登录社区编码
const scrollTop = 'scrollTop'
export function setScrollTop(id) {
  return Cookies.set(scrollTop, id)
}
export function getScrollTop() {
  return Cookies.get(scrollTop)
}
export function removeScrollTop() {
  return Cookies.remove(scrollTop)
}
